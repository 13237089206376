.stav {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.zelena {
    background-color: #66e74f !important;
}

.svetle-zelena {
    background-color: greenyellow !important;
}

.oranzova {
    background-color: yellow !important;
}

.cervena {
    background-color: red !important;
}