.dropdown {
    display: inline-block;
}

.dropdown-toggle {
    padding: 0;
}

.dropdown-toggle::after {
    display: none;
}

table thead th.ascending::after {
    content: '▼';
    display: inline-block;
    margin-left: 0.5em;
}

table thead th.descending::after {
    content: '▲';
    display: inline-block;
    margin-left: 0.5em;
}