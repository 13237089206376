.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loader>.loader-icon {
  font-size: 64px;
}

.wrapper {
  padding: 20px;
}

.title {
  margin: 50px 30px;
}

.content-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar {
  background-color: #3c3c3c;
  padding-left: 20px;
  padding-right: 20px;
}

#basic-navbar-nav {
  justify-content: flex-end;
}

.table {
  margin-bottom: 0;
  /* TODO to nesmí být na všechny tabulky! */
  /* max-width: 1600px; */
}

.table th {
  vertical-align: top;
}

.table> :not(caption) .trash-icon {
  color: rgb(0, 89, 255);
  cursor: pointer;
  margin-left: 10px;
  padding: 0;
}

.table ul {
  padding: 0;
  margin-left: 20px;
  margin-bottom: 0;
}

.table td {
  vertical-align: top;
}

.table>tbody>tr>td>table>tbody>tr>td {
  border: none;
}

.action-icon {
  color: rgb(0, 89, 255);
  cursor: pointer;
}

.action-icon:not(:last-child) {
  margin-right: 15px;
}

.sortable {
  cursor: pointer;
}

.nehotovo {
  background-color: red !important;
}

.rozpracovano {
  background-color: aqua !important;
}

.hotovo {
  background-color: lime !important;
}

.narozeniny {
  background-color: yellow !important;
}