.login {
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.login-inner>form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error {
  font-size: small;
  color: red;
}